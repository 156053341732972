import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="footer-container footer-items-container">
          <div className="footer__image">
            <a
              target="_blank"
              href="https://vk.com/lifestyle_boardgames"
              className="image__item"
            >
              <img loading="lazy" src="/footer/vk.webp" alt="" />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/user/LifeStyleLtdRu"
              className="image__item"
            >
              <img loading="lazy" src="/footer/yt.webp" alt="" />
            </a>
          </div>
          <div className="footer__about">
            Дистрибьютор в России ООО "Настольные игры - Стиль жизни"
            <span>LifeStyleLtd.ru | +7 (495) 510-05-39</span>
            <span>mail@lifestyleltd.ru</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
