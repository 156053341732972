import { useEffect, useState, useRef } from "react";
import { scroller } from "react-scroll";

const Menu = () => {
    const [scroll, setScroll] = useState(0);
    const [offsetTop, setOffsetTop] = useState(300);
    const [size, setSize] = useState(window.screen.width);
    const [activeItem, setActiveItem] = useState("");
    const handleScroll = () => {
        setScroll(window.scrollY);
    };
    const scrollAnimate = (link) => {
        scroller.scrollTo(link, {
            smooth: true,
            offset: size < 768 ? -80 : -120,
        });
    };
    const menuRef = useRef(null);
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    useEffect(() => {
        setOffsetTop(menuRef.current.offsetTop);
    }, []);
    useEffect(() => {
        window.addEventListener("resize", () => setSize(window.screen.width));
        return () =>
            window.removeEventListener("resize", () =>
                setSize(window.screen.width)
            );
    }, []);
    return (
        <div
            ref={menuRef}
            className={`header__nav ${
                scroll > offsetTop ? "header__nav-fixed" : ""
            }`}
        >
            <div
                className={`header__nav__item ${
                    activeItem === "about-game"
                        ? "header__nav__item-active"
                        : ""
                }`}
            >
                <a
                    onClick={() => {
                        scrollAnimate("about-game");
                        setActiveItem("about-game");
                    }}
                >
                    об игре
                </a>
            </div>
            <div
                className={`header__nav__item ${
                    activeItem === "ordering" ? "header__nav__item-active" : ""
                }`}
            >
                <a
                    onClick={() => {
                        scrollAnimate("ordering");
                        setActiveItem("ordering");
                    }}
                >
                    цены
                </a>
            </div>
            <div
                className={`header__nav__item ${
                    activeItem === "composition"
                        ? "header__nav__item-active"
                        : ""
                }`}
            >
                <a
                    onClick={() => {
                        scrollAnimate("composition");
                        setActiveItem("composition");
                    }}
                >
                    состав
                </a>
            </div>
            <div
                className={`header__nav__item ${
                    activeItem === "how-to-play"
                        ? "header__nav__item-active"
                        : ""
                }`}
            >
                <a
                    onClick={() => {
                        scrollAnimate("how-to-play");
                        setActiveItem("how-to-play");
                    }}
                >
                    как играть
                </a>
            </div>
            <div
                className={`header__nav__item ${
                    activeItem === "reviews" ? "header__nav__item-active" : ""
                }`}
            >
                <a
                    onClick={() => {
                        scrollAnimate("reviews");
                        setActiveItem("reviews");
                    }}
                >
                    обзоры
                </a>
            </div>
        </div>
    );
};

export default Menu;
