import { lazy, useState } from "react";
import unfoldingArray from "./unfoldingArray";
import "./unfoldingVideo.css";
const UnfoldingVideo = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button className="item__button" onClick={() => setOpen(!open)}>
        <p>{open ? "закрыть" : "показать еще"}</p>
      </button>
      <div style={{ width: "100%" }}>
        {unfoldingArray?.map((item) => (
          <div
            className={`unfolding-video ${open ? "unfolding-video-open" : ""}`}
            key={item.id}
          >
            <h4 className="reviews__subtitle">{item.name}</h4>
            <div
              className="unfolding-video__item"
              dangerouslySetInnerHTML={{ __html: item.frame }}
            ></div>
          </div>
        ))}
      </div>
    </>
  );
};

export default UnfoldingVideo;
