import "./aboutGame.css";
import "./aboutGameMedia.css";
import Element from "react-scroll/modules/components/Element";
// import Timer from "./timer/Timer";
import Timer from "./timer/Timer";

const AboutGame = () => {
    return (
        <div>
            <Element name="about-game" />
            <div className="rew-container">
                <div style={{ position: "relative" }}>
                    <div className="welcome-moon__img">
                        <img src="/about-game/moon.png" alt="Moon box photo" />
                    </div>
                    <div className="about-game content-container">
                        <div className="items-container">
                            <div className="about-game welcome-moon">
                                <div className="welcome-moon__info info">
                                    <p className="info__greetings">
                                        Добро пожаловать на луну
                                    </p>
                                    <p className="info__greetings-eng">
                                        welcome to the moon (2022)
                                    </p>
                                    <div className="welcome-moon__icons">
                                        <img
                                            src="/about-game/info-icons/count.png"
                                            alt="count"
                                        />
                                        <img
                                            src="/about-game/info-icons/age.png"
                                            alt="age"
                                        />
                                        <img
                                            src="/about-game/info-icons/time.png"
                                            alt="time"
                                        />
                                        <img
                                            src="/about-game/info-icons/rules.png"
                                            alt="rules"
                                        />
                                    </div>
                                </div>
                            </div>
                            <img
                                src="/about-game/Rocket_line_top.png"
                                alt="rules"
                            />
                            <div className="welcome-moon__circulation-info">
                                <p>
                                    Тираж <span> 1000 шт.</span>
                                </p>
                                <p>
                                    Осталось <span>700 шт.</span>
                                </p>
                                <p>
                                    Продано <span>300 шт.</span>
                                </p>
                                <Timer countdownDay="2022-10-01" />
                            </div>
                            <p className="welcome-moon__delivery-date">
                                Предполагаемая дата поставки на склад − октябрь
                                2022 года.
                            </p>
                            <img
                                src="/about-game/Rocket_line_bottom.png"
                                alt="rules"
                            />

                            <p className="about-game__text">
                                <h4>
                                    «Жители Земли, мы находимся в критической
                                    ситуации. Наша планета обречена. Судьба
                                    человечества в ваших руках… Это уже не
                                    вопрос сохранения привычного образа жизни
                                    или экономики. Речь идёт о самом нашем
                                    существовании. Наше будущее зависит от того,
                                    сможем ли мы покорить Луну. Эту миссию –
                                    нашу последнюю надежду – мы поручаем вам. Вы
                                    будете соревноваться друг с другом, чтобы
                                    увеличить общие шансы на успех. Спасите
                                    человечество, написав новую страницу нашей
                                    истории. Добро пожаловать на Луну!»{" "}
                                    <h4 className="about-game__text-h4_1">
                                        <p>
                                            Выдержка из обращения Каталин
                                            Нельсон Блю,
                                        </p>
                                        <p>
                                            первого президента Объединённого
                                            Альянса
                                        </p>
                                    </h4>
                                </h4>
                            </p>

                            <iframe
                                loading="lazy"
                                src="https://www.youtube.com/embed/Wntudo6K9jw"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                            <p className="about-game__text">
                                <h4>
                                    «Добро пожаловать на Луну» – продолжение
                                    игры «Бумажные кварталы». Вы уже побывали в
                                    жилищных районах 1950-х, теперь вам
                                    предстоит покорить космос!
                                </h4>
                                <h4 className="about-game__text-h4_2">
                                    «Добро пожаловать на Луну» – это игра с
                                    полноценной сюжетной кампанией. Она содержит
                                    8 приключений, которые образуют единую
                                    историю. Каждое приключение предлагает
                                    уникальный игровой опыт: вам предстоит
                                    отправиться на Луну, основать колонию,
                                    справиться с эпидемией вируса и многое
                                    другое. Вы можете пройти каждое приключение
                                    независимо друг от друга или
                                    последовательно, чтобы создать собственную
                                    историю.
                                </h4>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGame;
