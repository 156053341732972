import "./rulevideo.css";

const Rulevideo = () => {
  return (
    <div className="rew-container" style={{ padding: "0px" }}>
      <div className="rulevideo__container content-container">
        <div className="rulevideo__video">
          <iframe
            width="100%"
            height="100%"
            loading="lazy"
            src="https://www.youtube.com/embed/yAnJwYAjL_o"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div className="rulevideo__button">
          {/* <a href="./rule-video/rule1.pdf" download> */}
          <a target="_blank" href="https://disk.yandex.ru/i/Xe9cfVYSKoeASw">
            <img loading="lazy" src="/button/button.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Rulevideo;
