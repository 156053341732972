import "./reviews.css";
import "./reviewsMedia.css";
import Element from "react-scroll/modules/components/Element";
import UnfoldingVideo from "./unfoldingVideo/UnfoldingVideo";

const Reviews = () => {
  return (
    <div className="rew-container" style={{ paddingBottom: "80px" }}>
      <Element name="reviews" />
      <div className="reviews content-container">
        <p className="reviews__title">Обзоры</p>
        <h4 className="reviews__subtitle">The Dice Tower</h4>
        <iframe
          loading="lazy"
          src="https://www.youtube.com/embed/j3JdwCPG8bo"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <h4 className="reviews__subtitle">Philibert</h4>
        <iframe
          loading="lazy"
          src="https://www.youtube.com/embed/iRHCd0HgXH8"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <UnfoldingVideo />
      </div>
    </div>
  );
};

export default Reviews;
