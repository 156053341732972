import "./compgame.css";
import Element from "react-scroll/modules/components/Element";

const compositgame = () => {
  return (
    <div className="rew-container">
      <Element name="composition" />
      <div className="composition__container content-container">
        <div className="composition__ ">
          <div className="composition__title">СОСТАВ ИГРЫ</div>
          <div className="composit__image">
            <img
              loading="lazy"
              src="./compositiongame/compositionGame.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default compositgame;
