const unfoldingArray = [
  {
    id: 1,
    name: `Un Monde de jeux`,
    frame: `<iframe loading="lazy" src="https://www.youtube.com/embed/_iU-n_PC_bk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
  {
    id: 2,
    name: `slickerdrips`,
    frame: `<iframe loading="lazy" src="https://www.youtube.com/embed/U_B-7bQ9Zao" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
  {
    id: 3,
    name: `Ludovox`,
    frame: `<iframe loading="lazy" src="https://www.youtube.com/embed/HQzEj6BL--Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
];
export default unfoldingArray;
