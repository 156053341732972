import "./howToPlay.css";
import "./howToPlayMedia.css";
import Element from "react-scroll/modules/components/Element";

const HowToPlay = () => {
  return (
    <div className="rew-container">
      <Element name="how-to-play" />
      <div className="page-container">
        <div className="htp content-container">
          <div className="items-container">
            <p className="htp__main-title">Как играть</p>
            <h4 className="htp__text">
              В этой игре вам предстоит вписывать номера в ячейки на своих
              игровых полях по определённым правилам. Каждый ход игроки выбирают
              одну из трёх доступных комбинаций карт (номер + эффект), вписывая
              номер в одну из свободных ячеек и применяя эффект.
            </h4>
            <img
              className="htp__img-cards"
              src="/how-to-play/cards.png"
              alt="cards"
              loading="lazy"
            />
            <h4 className="htp__text">
              Ячейки объединяются в зоны. В каждой зоне номера должны
              располагаться в порядке возрастания.
            </h4>
            <img
              className="htp__img-card"
              src="/how-to-play/card.png"
              alt="card"
              loading="lazy"
            />
            <h4 className="htp__text">
              <p>
                Номера не могут повторяться в пределах одной зоны. Вы можете
                оставить сколько угодно пустых ячеек между двумя ячейками с
                вписанными номерами и заполнить их позже, соблюдая порядок
                возрастания. Вы также можете пропустить один или несколько
                номеров (например, вписать 8 рядом с 6).
              </p>
              <p className="htp__text1">
                {" "}
                Каждое из приключений имеет отдельное игровое поле. Во всех них
                сохраняется базовая механика игры и используются одни и те же
                карты звездолётов, однако игровой процесс каждого приключения
                будет уникальным. Вы всегда будете применять одни и те же 6
                эффектов, но в каждом приключении они будут действовать
                по-разному.
              </p>
              <p className="htp__text2">
                {" "}
                Выстраивайте тактику при заполнении своего поля, применяйте
                эффекты и выполняйте миссии, чтобы набрать как можно больше
                очков!
              </p>
              <p className="htp__text3">
                Выберите для игры один из следующих режимов.
              </p>
            </h4>
            <div className="htp__title-block">
              <img
                src="/how-to-play/campaign.png"
                alt="campaign"
                loading="lazy"
              />
              <p className="htp__title-block__name">Приключение</p>
            </div>

            <h4 className="htp__text">
              Сыграйте в одно из 8 приключений. Игрок, набравший наибольшее
              количество очков, становится победителем.
            </h4>
            <div className="htp__title-block">
              <img
                src="/how-to-play/advanture.png"
                alt="advanture"
                loading="lazy"
              />
              <p className="htp__title-block__name">Кампания</p>
            </div>

            <h4 className="htp__text">
              <p>
                Книга кампаний проведёт вас через все 8 приключений по порядку.
                Более того, в ходе неё вы будете оказывать влияние на дальнейшую
                игру благодаря картам кампаний. Таким образом, каждый раз вы
                будете создавать новую историю. В этом режиме каждая последующая
                партия будет зависеть от предыдущей, однако вы всегда можете
                начать новую кампанию, ведь в ходе игры никакие компоненты не
                уничтожаются и с ними не происходит необратимых изменений.
              </p>
              <p className="htp__text4">
                Для игры в этом режиме вам понадобится книга кампаний: она
                проведёт вас дальше шаг за шагом. Мы рекомендуем начать с
                вступительной кампании, чтобы постепенно знакомиться с правилами
                каждого приключения.
              </p>
              <p className="htp__text5">
                В конце каждого приключения вы можете сохранить свой прогресс.
                Также вы получите определённое количество звёзд в зависимости от
                своих результатов. Игрок, собравший по итогам кампании
                наибольшее количество звёзд, становится её победителем.
              </p>
            </h4>
            <div className="htp__title-block">
              <img
                src="/how-to-play/solo-mode.png"
                alt="solo-mode"
                loading="lazy"
              />
              <p className="htp__title-block__name">Соло-режим Астра</p>
            </div>

            <h4 className="htp__text">
              <p>
                Частное космическое агентство АСТРА (Агентство Стратегических
                Технологий, Роботов и Астронавтики) под управлением «Алекси
                инкорпорейтед» — ваш главный конкурент в этой космической гонке.
                Его цель — захватить наиболее ценные ресурсы и установить свою
                власть. Это недопустимо! Мы рассчитываем на вас!
              </p>
              <p className="htp__text6">
                Все стандартные и специальные правила действуют как обычно, за
                исключением некоторых изменений. Вы также найдёте дополнительные
                правила для соло-режима в соответствующем разделе специальных
                правил выбранного приключения.
              </p>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToPlay;
