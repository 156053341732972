import "./header.css";

import Menu from "./menu/Menu";

const Header = () => {
  return (
    <header>
      <div className="header">
        <div className="header__background page-container">
          <div className="header__container">
            <div className="header__welcome">
              <img
                className="header__left__img"
                src="header/Header_Title_line_left.png"
                alt=""
              />
              <p>
                Добро пожаловать <br /> <span>на луну</span>
              </p>
              <img
                className="header__right__img"
                src="header/Header_Title_line_right.png"
                alt=""
              />
            </div>
            <Menu />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
