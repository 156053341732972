import React, { useEffect, useState } from "react";

function Timer({ countdownDay }) {
    const [timerDays, setTimerDays] = useState();

    let interval;

    const startTimer = () => {
        const countDownDate = new Date(countdownDay).getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();

            const distance = countDownDate - now;

            const days = Math.floor(distance / (24 * 60 * 60 * 1000));

            if (distance < 0) {
                clearInterval(interval.current);
            } else {
                setTimerDays(days);
            }
        });
    };
    useEffect(() => {
        startTimer();
    });
    return timerDays > 0 ? (
        <p>
            Осталось <span>{timerDays} дней.</span>
        </p>
    ) : (
        <p>
            Осталось <span>0 дней.</span>
        </p>
    );
}

export default Timer;
