import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Rulevideo from "../components/rulevideo/Rulevieo";
import Reviews from "../components/reviews/Reviews";
import HowToPlay from "../components/how-to-play/HowToPlay";
import AboutGame from "../components/about-game/AboutGame";
import Compositiongame from "../components/compositiongame/Compositiongame";
import Ordering from "../components/ordering/Ordering";

const Main = () => {
  return (
    <div>
      <Header />
      <AboutGame />
      <Ordering />
      <Compositiongame />
      <HowToPlay />
      <Rulevideo />
      <Reviews />
      <Footer />
    </div>
  );
};

export default Main;
