import "./ordering.css";
import Element from "react-scroll/modules/components/Element";

const Ordering = () => {
    return (
        <div className="rew-container">
            <Element name="ordering" />
            <div className="item-card__container items-container">
                <div className="items__order">
                    <div className="item__card__block">
                        <img
                            loading="lazy"
                            src="orderprod/box1.png"
                            className="box1"
                            alt=""
                        />
                        <div className="items__card__layer">
                            <div className="item__card">
                                <div className="item__title">
                                    <p>
                                        Добро пожаловать <br /> на Луну
                                    </p>
                                </div>
                                <div className="item__sumcontainer">
                                    <div className="item__subsum">
                                        <p>2890 руб.</p>
                                    </div>
                                    <div className="item__sum">
                                        <p>2590 руб.</p>
                                    </div>
                                    <a
                                        target="_blank"
                                        href="https://www.igroved.ru/games/welcome/moon-dobro-pozhalovat-na-lunu/"
                                        className="item__button"
                                    >
                                        <p>Заказать</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="order__about__container">
                    <div className="order__text__container">
                        <div className="order__text">
                            <ul>
                                <li>
                                    <tr>
                                        <p>
                                            <span>
                                                <a>В период предзаказа</a>
                                            </span>
                                            до 1 октября стоимость игры
                                            <br /> «Добро пожаловать на Луну»
                                            составит 2590 рублей.
                                        </p>
                                    </tr>
                                </li>
                            </ul>
                        </div>
                        <div className="order__text">
                            <ul>
                                <li>
                                    <tr>
                                        <p>
                                            <span>
                                                <a>Далее до поступления игры</a>
                                            </span>
                                            цена будет составлять 2590 руб. и на
                                            выходе тиража 2890 руб.
                                        </p>
                                    </tr>
                                </li>
                            </ul>
                        </div>
                        <div className="order__text">
                            <ul>
                                <li>
                                    <tr>
                                        <p>
                                            <span>
                                                <a>Набор из двух игр</a>
                                            </span>
                                            можно будет купить только в период
                                            предзаказа до 1 октября.
                                        </p>
                                    </tr>
                                </li>
                            </ul>
                        </div>
                        <div className="order__text">
                            <ul>
                                <li>
                                    <tr>
                                        <p>
                                            <span>
                                                <a>Оформленный предзаказ</a>
                                            </span>
                                            необходимо оплатить в течение 3
                                            дней, далее он будет отменен
                                            автоматически.
                                        </p>
                                    </tr>
                                </li>
                            </ul>
                        </div>
                        <div className="order__text">
                            <ul>
                                <li>
                                    <tr>
                                        <p>
                                            <span>
                                                <a>В период предзаказа</a>
                                            </span>
                                            можно воспользоваться рассрочкой,
                                            платёж за игру можно разбить на три
                                            части.
                                        </p>
                                    </tr>
                                </li>
                            </ul>
                        </div>
                        <div className="order__text">
                            <ul>
                                <li>
                                    <tr>
                                        <p>
                                            <span>
                                                <a>При оформлении игры</a>
                                            </span>
                                            игры в рассрочку заказ на первую
                                            часть нужно оплатить в течение 3
                                            дней после создания заказа.
                                        </p>
                                    </tr>
                                </li>
                            </ul>
                        </div>
                        <div className="order__text">
                            <ul>
                                <li>
                                    <tr>
                                        <p>
                                            <span>
                                                <a>Покупка игры в рассрочку</a>
                                            </span>
                                            доступна до 15 августа.
                                        </p>
                                    </tr>
                                </li>
                            </ul>
                        </div>
                        <div className="order__text">
                            <ul>
                                <li>
                                    <tr>
                                        <p>
                                            <span>
                                                <a>Оплата следующей части</a>
                                            </span>
                                            должна быть произведена ровно через
                                            месяц, она автоматически спишется с
                                            карты в соответствующую дату
                                            предыдущей оплаты.
                                            <p style={{ marginTop: "10px" }}>
                                                Предупреждение об оплате
                                                поступит на почту за 1 сутки,
                                                просим обратить на это внимание!
                                            </p>
                                        </p>
                                    </tr>
                                </li>
                            </ul>
                        </div>
                        <div className="order__text">
                            <ul>
                                <li>
                                    <tr>
                                        <p>
                                            <span>
                                                <a>Доставка</a>
                                            </span>
                                            будет осуществляться нашим партнером
                                            магазином Игровед согласно его
                                            условиям доставки.
                                        </p>
                                    </tr>
                                </li>
                            </ul>
                        </div>
                        <div className="order__text">
                            <ul>
                                <li>
                                    <tr>
                                        <p>
                                            <span>
                                                <a>Точки самовывоза</a>
                                            </span>
                                            будут доступны в Москве и
                                            Санкт-Петербурге в магазинах
                                            Игровед.
                                        </p>
                                    </tr>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="order__under__img">
                    <img loading="lazy" src="orderprod/under.png" alt="" />
                </div>
            </div>
        </div>
    );
};

export default Ordering;
